<template>
  <div class="blogpost">
    <div class="blogpost__content--wide">
      <h1 class="blogpost__title">5 Remote Work Lessons From Project Manager</h1>
    </div>
    <div class="blogpost__content--full blogpost__content--color">
      <div class="blogpost__content">
        <div class="blogpost__author-wrapper">
          <div>
            <div class="blogpost__author">Paulina Czajka</div>
            <div class="blogpost__author-position">Co-founder of RemoteFun</div>
          </div>
          <div>13 Jul 2021</div>
        </div>
      </div>
    </div>
    <div class="blogpost__content">
      <p>
        More than two years ago I started my journey as an IT Project Manager. A while before COVID
        outbreak, the company I worked for used hybrid approach (but with a focus on remote work).
        <b>After March 2020, I started working remotely almost all the time.</b>
      </p>
      <p>
        As a prior office attender that mindset change was a bit challenging at first.
        <b>I'm a person who cares about relationships</b>
        with my team members and needs face-to-face contact.
      </p>
      <p>
        I couldn't imagine building a friendly atmosphere online but
        <b>I learnt some techniques.</b>
      </p>
      <h2>I'd like to share them with you.</h2>
      <h4>Keep your camera on. 📹</h4>
      <p>
        Seeing each other during meetings is a game changer. It enables you to feel like you're in
        the same room, you can see reactions and read emotions on your team members' faces. It might
        also make you feel like you're not talking to yourself.
      </p>
      <h4>Assume good intentions 🤝</h4>
      <p>
        Whenever you communicate on chat, don't analyse too much what the other person wrote. For
        example, why the other person didn't use emoji while talking to you - it doesn't necessarily
        mean that they're mad at you or something. Instead try to think that if this person would be
        mad at you, they would've probably told you that!
      </p>
      <h4>More fun, less stress 🥳</h4>
      <p>
        Do you miss playing on PlayStation at the office or gossiping over coffee with your
        colleagues? Maybe a good solution is to book a slot in a calendar for your team and meet
        remotely for a coffee or play some games online. Use RemoteFun to
        <b>play custom version of Monopoly</b>
        , set up a timer and spend a nice 30 minutes with your team making them go bankrupt.
      </p>
      <h4>Get to know others 👩‍💻</h4>
      <p>
        Since the pandemic started, how many people in your company went through the onboarding
        process remotely? Do you know them? Do the other team members know them? I think you could
        use a tool to break the ice here -
        <b>and this tool is FunIceBreaker!</b>
        In just 15 min you will have a chance to get to know your teammates better!
      </p>
      <h4>Bonding time 🔗</h4>
      <p>
        Building relationships with your team is a real challenge and a very long process. How about
        a team building exercise? Do you know this Polish show 'Awantura o kasę' or New Zealand
        version 'Cash battle'?
        <b>FunTeamBidding is all about building strong team connections</b>
        through casual yet competitive team game.
      </p>
      <h2>RemoteFun will help you with remote work challenges!</h2>
      <p>
        The reason we created
        <b>RemoteFun</b>
        is that we know how important relationships are for the success of a company.
      </p>
      <p>
        The platform is dedicated for people who lead their teams remotely, care about their
        well-being and friendly atmosphere at work.
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RemoteFunCustomizableTeamBuilding'
  };
</script>

<style scoped lang="scss"></style>
