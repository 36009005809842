<template>
  <div class="blogpost">
    <div class="blogpost__content--wide">
      <h1 class="blogpost__title">5 wskazówek od Project Managerki jak budować zespoły zdalne</h1>
    </div>
    <div class="blogpost__content--full blogpost__content--color">
      <div class="blogpost__content">
        <div class="blogpost__author-wrapper">
          <div>
            <div class="blogpost__author">Paulina Czajka</div>
            <div class="blogpost__author-position">Co-founder RemoteFun</div>
          </div>
          <div>13 lipca 2021</div>
        </div>
      </div>
    </div>
    <div class="blogpost__content">
      <p>
        Ponad dwa lata temu zaczęłam pracę w IT jako Project Managerka. Tuż przed wybuchem pandemii
        COVID-19 pracowaliśmy już w formie hybrydowej, jednak w marcu 2020
        <b>zaczęliśmy pracować jedynie zdalnie.</b>
      </p>
      <p>
        Wcześniej często bywałam w biurze i lubię taki tryb pracy, wobec tego musiałam zmienić swoje
        nastawienie i zastanowić się jak najlepiej do tego podejść.
      </p>
      <p>
        Nie wyobrażałam sobie, że można budować tak dobrą atmosferę w zespołach pracując zdalnie,
        jednak pewne techniki i narzędzia mogą w tym pomóc.
      </p>
      <h2>Chciałabym się z Tobą nimi podzielić.</h2>
      <h4>Włącz kamerkę 📹</h4>
      <p>
        Widzenie siebie nawzajem podczas spotkań zmienia całkowicie ich dynamikę. Pozwala Ci choć
        trochę poczuć jak byście byli w tym samym pokoju.
        <b>Dzięki temu możesz także widzieć reakcję innych i wyczuwać ich emocje.</b>
        Jeśli chodzi o Ciebie, to na pewno nie będziesz się wtedy czuć jakbyś mówił/a do ekranu.
      </p>
      <h4>Zakładaj dobre intencje 🤝</h4>
      <p>
        Komunikacja na chacie jest bardzo trudna. Stąd ważne jest założenie dobrych intencji, gdy
        ktoś do Ciebie pisze. Jeśli nie używa emoji, nie znaczy to że jest na Ciebie zły albo ma
        kiepski humor. Bardzo ważne jest żeby poznać się i porozmawiać na żywo. To daje lepszą
        perspektywę na przyszłą komunikację wirtualną.
      </p>
      <p>
        Mamy tendencję do czytania wiadomości od innych w jakiś sposób, z jakimś tonem, który może
        być całkowicie inny niż intencje danej osoby.
        <b>Takie podejście może zaszkodzić</b>
        , więc jeśli masz takie wrażenie, po prostu napisz jasno co czujesz i dogadajcie się.
      </p>
      <h4>Więcej zabawy = mniej stresu 🥳</h4>
      <p>
        Brakuje Ci grania na PlayStation w biurze albo ploteczek w kuchni? Zarezerwuj czas w
        kalendarzu swojego zespołu na takie rozrywki. Pogadajcie o niczym albo pograjcie w coś
        online.
      </p>
      <p>
        Możesz użyć RemoteFun i np.
        <b>zagrać w dopasowaną pod Twój biznes wersję Monopoly.</b>
        Przekaż trochę firmowych historyjek i ciekawostek w kartach szansy. Ustaw timer np. na 30
        minut i spędźcie ten czas próbując wpakować innych w wirtualne bankructwo.
      </p>
      <h4>Poznajcie się 👩‍💻</h4>
      <p>
        Ile osób dołączyło do Waszego zespołu od kiedy pracujecie zdalnie? Istnieje możliwość, że
        części z nich nigdy nie zobaczyłeś/aś na żywo.
      </p>
      <p>
        Poznaj ich lepiej przez FunIceBreaker,
        <b>dzięki któremu dowiecie się trochę ciekawostek o sobie</b>
        i zobaczycie kto jest najlepszym kłamcą. To jedynie 15-20 minut zabawy, a będziecie mieli
        szansę żeby lepiej się poznać!
      </p>
      <h4>Tworzenie mocnej więzi 🔗</h4>
      <p>
        Zdalne budowanie relacji w zespole jest nie lada wyzwaniem i długim procesem. Pomóc mogą
        ćwiczenia lub gry team buildingowe, a jedną z nich - FunTeamBidding, znajdziesz także w
        naszej ofercie. Zbudowane na podstawie 'Awantury o Kasę',
        <b>FunTeamBidding pozwoli Ci na rywalizację między zespołami</b>
        i zbudowanie poczucia przynależności do teamu i organizacji.
      </p>
      <p>
        Porozmawiajcie w czym jesteście dobrzy, a w czym słabsi i wykorzystajcie tę wiedzę do
        odpowiadania na pytania z podanych kategorii. Podejmijcie ryzyko w postaci zagrania va
        banque i zgarnięcia podwójnej puli punktów.
      </p>
      <h2>RemoteFun pomoże Ci z wyzwaniami pracy zdalnej.</h2>
      <p>
        Stworzyliśmy
        <b>RemoteFun</b>
        bo wiemy jak ważne są relacje międzyludzkie w kontekście sukcesu Twojego biznesu i
        lojalności pracowników.
      </p>
      <p>
        Platforma jest dedykowana do każdego kto ma w zespołach osoby pracujące zdalnie. Jeśli
        zależy Ci na atmosferze w zespole i na relacjach Twoich współpracowników, to narzędzie jest
        dla Ciebie!
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RemoteFunCustomizableTeamBuilding'
  };
</script>

<style scoped lang="scss"></style>
