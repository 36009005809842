<template>
  <div class="blogpost">
    <div class="blogpost__content--wide">
      <h1 class="blogpost__title">RemoteFun - Customizable Virtual TeamBuilding</h1>
    </div>
    <div class="blogpost__content--full blogpost__content--color">
      <div class="blogpost__content">
        <div class="blogpost__author-wrapper">
          <div>
            <div class="blogpost__author">Bartosz Bilejczyk</div>
            <div class="blogpost__author-position">Founder of RemoteFun</div>
          </div>
          <div>03 Jul 2021</div>
        </div>
      </div>
    </div>
    <div class="blogpost__content">
      <p>
        Remote work is a hot topic in 2021. Many companies and their employees were forced to change
        the way they work. There is a big chance that last 18 months changed many workplaces for
        good.
      </p>
      <p>
        Will we get back to offices? Even though there will be people eager to do that, many found
        that remote work (mostly at home) is a great alternative for them - without commuting and
        e.g. noisy open spaces.
      </p>
      <p>
        <b>
          There is no doubt, that remote work comes with a big challenge for both employees and
          employers in the long run.
        </b>
        It's a situation that naturally moves us into less natural and face-to-face contact with
        other people. This may cause not only solitude, but also lack of loyalty and affiliation
        with your company.
      </p>
      <h5>
        How to naturally encourage people to continue working for you, while also maintaning high
        level of effectiveneess, engagement and motivation?
      </h5>
      <b>Focus on relationships and how your teams are bonded.</b>
      <p>
        If you have good atmosphere in your team, every crisis will be resolved and every
        opportunity taken with engagement. Most importantly though, your employees will just feel
        good and purposeful in the long run.
        <b>Such long-term thinking about your employees is key to your company's success.</b>
      </p>
      <p>
        Building relationships can be done through variety of ways. In remote world, one of them
        could be
        <b>online team competition</b>
        and meeting people through
        <b>casual and engaging games</b>
        .
      </p>
      <p>
        Let us help you
        <b>achieve that with RemoteFun!</b>
      </p>

      <h2>Team building nowadays</h2>
      <p>
        Team building is a great way to build relationships and have a little fun. When your
        employees work remotely, and there’s not much chance to get them in one place, it’s still
        possible to do it online, right?
      </p>
      <p>
        There are different ways to approach this. Some of such activities suit small teams or big
        teams. Such exercises and games can also be divided for those who know themselves quite well
        and those who meet for the first time.
      </p>
      <p>
        At RemoteFun we did precisely that. We focused on delivering value for you in cases where
        you need:
      </p>
      <ul>
        <li>Icebreakers for new teams and people who know each other for some time.</li>
        <li>
          Icebreakers for workshops with a client to build rapport and good vibe for collaboration.
        </li>
        <li>Create a knowledge competition between teams</li>
        <li>Create a tournament for your company</li>
        <li>Play a game similar to Monopoly, but customized to fit your specific business.</li>
      </ul>

      <h2>Our offer</h2>
      <p>
        RemoteFun starts August 9th 2021. Currently we already have
        <b>3 games and 1 additional juggling(!) module</b>
        in development.
      </p>
      <p>
        <b>Every game can be customized freely.</b>
        Branding - colors and logo, questions, game settings. It’s your choice how you’ll setup the
        games for your company. You can of course choose our presets, to make your life easier.
      </p>

      <div class="video-wrapper">
        <iframe
          :src="`https://www.youtube.com/embed/zaA8U3DByYc?rel=0`"
          frameborder="0"
          allowfullscreen="allowfullscreen"
        ></iframe>
      </div>

      <p>Check the games below.</p>

      <h3>FunTeamBidding</h3>
      <p>Suitable for teams from 4 to even 20-30 people.</p>
      <p>
        Form teams and bid the price to be able to answer a question from the preselected category.
      </p>
      <p>
        <b>Everyone's an expert in something.</b>
        Combine your strengths and go for the win!
      </p>
      <p>
        <b>Are you a risk taker?</b>
        Go va banque for double jackpot price!
      </p>
      <p>
        <b>Don’t have enough money?</b>
        Take a loan and try to come back to the game.
      </p>
    </div>

    <div class="blogpost__images"></div>
    <div class="blogpost__content">
      <h3>FunIceBreaker</h3>
      <p>
        Suitable for teams from 3 to 8. If you have more people happy to join the integration, just
        create more games.
      </p>
      <p>
        This game is perfect for new teams and for people who already know each other. Answer the
        question, then match other players’ names with their answers, then talk to each other about
        your answers.
      </p>
      <p>
        It's amazing how interesting the people around you are. Some of them have very interesting
        hobbies. Some of them went to an amazing place and they will share this story with you with
        a smile of their face.
      </p>
      <p>
        Take advantage of two types of questions available here -
        <b>single answer</b>
        where you have to match name and answer and
        <b>2 truths, 1 lie</b>
        , where you have to select where every person lied.
      </p>
    </div>

    <div class="blogpost__images"></div>

    <div class="blogpost__content">
      <h3>FunBusinessGame</h3>
      <p>
        It’s a game inspired by Monopoly, but reworked to match needs of our clients. Suitable for
        teams from 2 to 6-8 people.
      </p>
      <p>
        Want to run IT company and employ people to your teams?
        <b>No problem!</b>
        <br />
        Want to run restaurants in the city and fight for the market?
        <b>No problem!</b>
        <br />
        Want to build a fleet of cars and charge others for driving them?
        <b>No problem!</b>
        <br />
        Want to chase Pokemon cards and upgrade them during the game?
        <b>No problem!</b>
        <br />
      </p>
      <p><b>Our and Your imagination is the limit!</b></p>
      <p>
        Buy cards, upgrade them and take chance cards to swing the game in different directions.
        Have some fun while making other people bankrupt. In a virtual world, of course ;)
      </p>
    </div>

    <div class="blogpost__images"></div>
    <div class="blogpost__content">
      <h3>FunJuggling</h3>
      <p>
        FunJuggling will be available in September 2021. This is a module that will allow your
        employees to learn something new and fun.
      </p>
      <p>
        With some gamification and leaderboard added to this module, your employees will be more
        focused and have a more goal-oriented mindset.
      </p>

      <h2>Our values</h2>
      <p>
        With remote work comes great responsibility. While many companies move towards more percent
        of employees working like that, there has to be some help with this process along the way.
        One of such measures could be RemoteFun for you!
      </p>

      <h4>We value relationships</h4>
      <p>
        We came up with RemoteFun to take care of social interaction among employees. Even though
        pandemic may end soon, remote work will still be a struggle for many.
      </p>
      <h4>We offer you regular updates</h4>
      <p>
        As we're a young company, we will provide improvements and patches for games on a regular
        basis. We will also develop new games and modules.
      </p>
      <h4>We donate 10% of profit to mental health initiatives</h4>
      <p>
        Remote work and solitude poses serious mental issues threat to many people. We would like to
        help not only by our games, but also directly to those who already need it.
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RemoteFunCustomizableTeamBuilding'
  };
</script>

<style scoped lang="scss"></style>
