<template>
  <div class="blogpost">
    <div class="blogpost__content--wide">
      <h1 class="blogpost__title">RemoteFun - zdalny teambuilding dla Twojego zespołu</h1>
    </div>
    <div class="blogpost__content--full blogpost__content--color">
      <div class="blogpost__content">
        <div class="blogpost__author-wrapper">
          <div>
            <div class="blogpost__author">Bartosz Bilejczyk</div>
            <div class="blogpost__author-position">Founder RemoteFun</div>
          </div>
          <div>3 lipca 2021</div>
        </div>
      </div>
    </div>
    <div class="blogpost__content">
      <p>
        Praca zdalna to gorący temat w 2021 roku. Wiele firm było zmuszonych do przejścia w taki
        tryb z powodu pandemii COVID-19. Jest spora szansa, że ostatnie 18 miesięcy zmieniło oblicze
        miejsca pracy na długo.
      </p>
      <p>
        Czy wrócimy do biur? Niektórzy będą chętni, inni jednak odnaleźli w pracy zdalnej
        (najczęściej w domu) spokojne zacisze. Bez konieczności dojazdów i pracy np. w głośnej
        przestrzeni
        <i>open space</i>
        .
      </p>
      <p>
        <b>
          Nie ulega wątpliwości, że praca zdalna w dłuższym okresie to ogromne wyzwanie dla
          pracowników i przedsiębiorstw.
        </b>
        To sytuacja generująca zmniejszony kontakt z innymi ludźmi, a co za tym idzie - zanikające
        poczucie przynależności do organizacji.
      </p>
      <h5>
        Jak sprawić, aby pracownicy nie chcieli opuścić Twojej firmy, a jednocześnie utrzymywać ich
        zaangażowanie i motywację na wysokim poziomie?
      </h5>
      <b>Skup się na relacjach międzyludzkich.</b>
      <p>
        Anegdota z czasów studenckich. Gdy działałem w Samorządzie Studentów Szkoły Głównej
        Handlowej w Warszawie robiliśmy wiele projektów wymagających godzin pracy i wielu wyrzeczeń.
        Dostawaliśmy za to wynagrodzenie w postaci doświadczenia quasi-biznesowego i darmowych
        chipsów czy RedBulli.
        <b>Jednak głównym powodem, dla którego to robiliśmy, była zajawka i świetne relacje.</b>
        Mawialiśmy
        <em>"trzeba ze sobą wódkę pić"</em>
        , co w świecie profesjonalnym można przenieść na innego rodzaju formy teambuildingu.
      </p>
      <p>
        Jeśli masz dobre relacje ze swoim zespołem, przezwyciężysz każdy kryzys w projekcie i
        wspólnie wymyślicie rozwiązanie, a przede wszystkim - będzie się po prostu dobrze pracowało
        na co dzień.
        <b>Myślenie długoterminowe o pracownikach to klucz do sukcesu.</b>
      </p>
      <p>
        Budowanie relacji może się odbywać przez wspólną rywalizację i naturalne poznawanie się
        podczas zabawy. Obecnie często jest potrzeba, by robić to zdalnie. I z tym przychodzę do
        Ciebie jako RemoteFun.
      </p>

      <h2>Team building w 2021 roku.</h2>
      <p>
        Jest wiele opcji podejścia do integracji dla Twoich pracowników. Można pomyśleć w kategorii
        mały zespół vs duży zespół. Można też pomyśleć w kategorii - czy ludzie znają się od
        jakiegoś czasu, czy są to zupełnie obce dla siebie osoby?
      </p>
      <p>
        W RemoteFun zrobiliśmy dokładnie to. Skupiliśmy się na dostarczeniu Ci takiego pakietu,
        który zaspokoi kilka z tych potrzeb, mianowicie:
      </p>
      <ul>
        <li>Icebreakery dla nowych teamów i dla ludzi, którzy się już trochę znają.</li>
        <li>
          Icebreakery na warsztaty lub spotkania z klientami, na których chcecie zbudować tzw.
          raport i dobry vibe na dalszą współpracę.
        </li>
        <li>
          Stworzyć rywalizację między zespołami, aby wywołać w nich poczucie przynależności do
          zespołu.
        </li>
        <li>
          Zagrać w grę podobną do Monopoly, ale dopasowaną do Twojego biznesu, gdzie możesz
          przemycić fajne historyjki firmowe i szybko pomóc nowym osobom wejść w Twoją kulturę
          organizacyjną.
        </li>
        <li>Przyszłość pokaże co jeszcze dla Ciebie zrobimy!</li>
      </ul>

      <h2>Nasza oferta</h2>
      <p>
        RemoteFun startuje 9 sierpnia 2021 r. Obecnie mamy 3 gry, a w planach jest jeden moduł do
        nauki żonglerki w Twojej firmie.
      </p>
      <p>
        <b>Każda gra może być w pełni dostosowana do Twojej firmy i Twoich pomysłów.</b>
        Zmień kolory, logo, pytania i ustawienia gier. Możesz też oczywiście skorzystać z naszych
        ustawień i przykładowych pytań.
      </p>
      <div class="video-wrapper">
        <iframe
          :src="`https://www.youtube.com/embed/01BMYa_1Yy8?rel=0`"
          frameborder="0"
          allowfullscreen="allowfullscreen"
        ></iframe>
      </div>

      <p>Zobacz co dla Ciebie przygotowaliśmy.</p>

      <h3>FunTeamBidding</h3>
      <p>Gra dla zespołów - od 4 do nawet 20-30 osób.</p>
      <p>
        Utwórz zespoły, poznajcie swoje mocne i słabe strony, a następnie licytujcie się, który
        zespół ma odpowiedzieć na pytanie. Poprawna odpowiedź to zgarnięcie puli i przybliżenie się
        do wspólnego zwycięstwa.
      </p>
      <p>
        <b>Każdy jest ekspertem w jakiejś dziedzinie.</b>
        Połączcie siły i wygrajcie z innymi zespołami.
      </p>
      <p>
        <b>Lubisz ryzyko?</b>
        Zagraj va banque, zakończ licytację i podbij pulę x2.
      </p>
      <p>
        <b>Nie masz wystarczająco kasy?</b>
        Weź pożyczkę i spróbuj wrócić do gry.
      </p>
    </div>

    <div class="blogpost__images"></div>
    <div class="blogpost__content">
      <h3>FunIceBreaker</h3>
      <p>
        Dla 3-8 graczy. Jeśli więcej osób chce zagrać, wygeneruj np. 2 gry, żeby każdy mógł się
        wypowiedzieć i poznać dobrze inne osoby.
      </p>
      <p>
        Ta gra jest świetna dla ludzi, którzy jeszcze się nie znają, ale także dla tych, którzy chcą
        wzmocnić relację i po prostu się razem rozerwać.
      </p>
      <p>
        To niesamowite jak interesujący są ludzie, gdy wyciągniesz z nich parę faktów o ich życiu
        czy zainteresowaniach. Na co dzień nie ma czasu o tym rozmawiać.
      </p>
      <p>
        Korzystaj z różnych typów pytań -
        <b>pojedynczej odpowiedzi</b>
        gdzie każdy gracz musi dopasować odpowiedzi innych do ich imion oraz
        <b>2 prawdy, 1 kłamstwo</b>
        , gdzie trzeba dla każdej osoby wybrać jakie stwierdzenie było kłamstwem.
      </p>
    </div>

    <div class="blogpost__images"></div>

    <div class="blogpost__content">
      <h3>FunBusinessGame</h3>
      <p>
        To gra inspirowana Monopoly, ale przerobiona tak, aby pasowała do potrzeb Twojego biznesu.
        Odpowiednia dla grup od 2 do 8 osób.
      </p>
      <p>
        Chcesz prowadzić firmę IT i zatrudniać ludzi do swoich teamów?
        <b>Nie ma problemu!</b>
        <br />
        Chcesz prowadzić restauracje i bić się o rynek?
        <b>Nie ma problemu!</b>
        <br />
        Chcesz zbudować flotę samochodów i pobierać opłaty za ich wynajem?
        <b>Nie ma problemu!</b>
        <br />
        Chcesz zbierać karty Pokemonów i upgrade'ować je podczas gry?
        <b>Nie ma problemu!</b>
        <br />
      </p>
      <p>
        <b>
          Nasze i Twoje pomysły na grę są ograniczone tylko wyobraźnią. No i trochę możliwościami
          technicznymi, ale nie myślmy o tym podczas brainstormu.
        </b>
      </p>
      <p>
        Kupuj karty, rozwijaj je, korzystaj z kart szansy, gdzie możesz przemycić fajne firmowe
        historię i smaczki. Przechyl szalę zwycięstwa na swoją stronę. Celem gry jest sprawienie,
        aby jakiś gracz zbankrutował.
      </p>
    </div>

    <div class="blogpost__images"></div>
    <div class="blogpost__content">
      <h3>FunJuggling</h3>
      <p>
        FunJuggling będzie dostępny w październiku 2021. To moduł, który pozwoli Twoim pracownikom
        nauczyć się fajnej umiejętności, która wpływa bardzo pozytywnie na skupienie i wyobraźnię.
      </p>
      <p>
        Z dodaną gamifikacją i tabelą liderów, możesz na przykład nagradzać najlepszych pracowników
        za ich postępy. Regularne żonglowanie na pewno przyczyni się do budowania mindsetu, który
        opiera się na skupieniu na danym celu.
      </p>

      <h2>Nasze wartości</h2>
      <p>
        Praca zdalna to duża odpowiedzialność. Gdy wiele firm idzie w kierunku zwiększenia procentu
        ludzi pracujących zdalnie, muszą iść za tym konkretne działania, które nie pozwolą na
        pewnego rodzaju upadek przynależności pracowników i tożsamości Twojej firmy. Z RemoteFun
        chcemy Ci w tym pomóc.
      </p>

      <h4>Relacje są dla nas ważne.</h4>
      <p>
        Wymyśliliśmy RemoteFun, aby zadbać o relacje międzyludzkie w firmach. Nawet jeśli pandemia
        się niedługo skończy, wiele osób będzie chciało pracować zdalnie. Poza tym, coraz więcej
        firm ma pracowników z różnych miast i różnych krajów, więc te osoby raczej nie zobaczą się i
        tak na żywo.
      </p>
      <h4>Oferujemy regularne aktualizacje.</h4>
      <p>
        Jesteśmy młodą firmą z produktem, który będziemy rozwijać. Możesz liczyć, że Twój feedback
        będzie słuchany uważnie.
      </p>
      <h4>Przeznaczamy 10% zysków na cele charytatywne związane ze zdrowiem psychicznym.</h4>
      <p>
        Praca zdalna i samotność tworzą zwiększone ryzyko występowania depresji i stanów lękowych w
        społeczeństwie. Chcemy pomóc nie tylko grami, ale też finansowo - wspierając tych, którzy
        profesjonalnie pomagają osobom borykającym się z takimi problemami.
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'RemoteFunZdalnyTeamBuilding'
  };
</script>

<style scoped lang="scss"></style>
